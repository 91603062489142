export enum Mark {
    None,
    Bomb,
    Unknown,
}

export enum GameStatus {
    InProgress,
    Lost,
    Won,
}
